import Link from 'next/link';

export default [
  {
    question: 'When is a new game available?',
    answer: (
      <p className="text-sm md:text-base">
        A new game is available every day at 0:00 am, local time of your device!
      </p>
    ),
  },
  {
    question: 'What year & location do you use?',
    answer: (
      <>
        <p className="text-sm md:text-base">
          In this game, we use the “in-universe” year and location of the scene
          shown in the image.
        </p>
        <p className="text-sm md:text-base">
          Example Image:{' '}
          <span className="italic">
            The 100M dash from the 1924 Paris Olympics in the film, “Chariots of
            Fire”
          </span>
        </p>
        <p className="text-sm md:text-base mt-2 font-bold underline">
          “In-Universe” (Correct) Data for “Chariots of Fire”
        </p>
        <p className="text-sm md:text-base">
          Location: Colombes Stadium, (suburb of) Paris
          <br />
          Year: 1924 (Olympics)
        </p>
        <p className="text-sm md:text-base mt-2">
          <span className="font-bold">
            Other (Incorrect) Answers for “Chariots of Fire” ←
          </span>{' '}
          Although interesting, these are{' '}
          <span className="font-bold underline">not</span> the answers we’re
          looking for…
          <br />
          Filming Location: Bebington Oval Sports Centre, Wirral, UK
          <br />
          Filming Year: 1980
          <br />
          Film Release Year: 1981
        </p>
      </>
    ),
  },
  {
    question: 'What if the movie doesn’t state the exact year or is wrong?',
    answer: (
      <>
        <p className="text-sm md:text-base">
          If a movie doesn’t explicitly state the year, we do our best to
          estimate the “in-universe” year and then add a reasonable buffer, that
          way you’ll still get a perfect score if you’re within that timeframe.
          For example, if we estimate the year to be 1970 and add a ± buffer of
          2 years, any guesses from 1968-1972 will be considered “spot on.”
        </p>
        <p className="text-sm md:text-base mt-2">
          In some cases, movies don’t state a year but are meant to be
          “contemporaneous.” In this case, we typically set the Year as one year
          before release because that’s when filming took place, but then we add
          a buffer of 1 year, meaning the release year will also net you a
          perfect score.
        </p>
        <p className="text-sm md:text-base mt-2">
          In still other cases, when we’re less certain, we will increase the
          buffer size, creating a larger cushion. For example, a movie may refer
          to a decade but not the specify the exact year, which might lead us to
          create a buffer of 5 years.
        </p>
        <p className="text-sm md:text-base mt-2">
          With all this said, we are human, and can make mistakes. If you find a
          mistake, please, report it and suggest another set of coordinates
          through the form by clicking on the ⚠️ button. We really appreciate
          the input from locals who help us make our data more precise.
        </p>
      </>
    ),
  },
  {
    question:
      'What if the movie scene’s location can’t be exactly determined or is incorrect?',
    answer: (
      <>
        <p className="text-sm md:text-base">
          We research each film and do our best to find the exact location. We
          put a lot of time and effort into this process in hopes that you will
          have a great gaming experience. However, we know that some locations
          will be impossible to exactly pinpoint or we might be slightly off.
          For this reason, our scoring system has a buffer, such that any guess
          within ~10km will still net you a perfect score. See more under our
          Scoring Rules.
        </p>
        <p className="text-sm md:text-base mt-2">
          If you find a mistake, please, report it and suggest another set of
          coordinates through the form by clicking on the ⚠️ button. We really
          appreciate the input from movie buffs who help us make our data more
          precise.
        </p>
      </>
    ),
  },
  {
    question: 'Can I play past games?',
    answer: (
      <p className="text-sm md:text-base">
        Yes, you can head to the Archive section from the menu and play all of
        the WhenTaken Movies archive games starting from the 13th of January
        2025.
      </p>
    ),
  },
  {
    question: 'What are the scoring rules?',
    answer: (
      <div className="flex flex-col gap-3 text-sm md:text-base">
        <div>
          Each game consists of 5 rounds with a maximum of 1,000 points possible
          (200 points per round). Each round is scored based on two factors:
          <ul className="mt-2 flex flex-col gap-1 md:ml-2">
            <li>• Location accuracy (100 points maximum per round)</li>
            <li>• Year accuracy (100 points maximum per round)</li>
          </ul>
        </div>
        <div>
          <div className="font-bold text-2xl mb-2">Scoring system</div>
          <p>
            Both year and location scores start at 100 points, with deductions
            based on how far your guess is from the correct answer. The Total
            Score is simply the year and location scores added together.
          </p>
          <div className="font-bold text-xl mt-4 mb-2">Year Score</div>
          <p>
            We start by establishing the "year gap"—the gap between your guessed
            year and the correct year. For images with a ± year buffer, the year
            gap is calculated by using the end of the range that is closer to
            your guess (e.g. 1990 and 2002 are both 4 years away from a correct
            year range of 1994-1998). Based on your "year gap," we apply the
            below deductions from the appropriate scoring bracket:
          </p>
          <ol className="my-2 list-decimal ml-5">
            <li className="my-2">
              0-5 years: Start at 100 points, -1 per year
            </li>
            <li className="my-2">
              6-10 years: Start at 95 points (-5 from previous deductions), -2
              per year over 5
            </li>
            <li className="my-2">
              11-20 years: Start at 85 points (-15 from previous deductions), -3
              per year over 10
            </li>
            <li className="my-2">
              Beyond 20 years: Start at 55 points (-45 from previous
              deductions), -4 per year over 20
            </li>
          </ol>
          <p className="italic">
            Example: If the correct year is 1944 and you guess 1960, we
            establish a "year gap" of | 1944 − 1960 | ={' '}
            <span className="font-bold">16 years</span>. For scoring, we fall
            into the 11-20 year range, thus we start at 85 points (due to
            previous deductions for first 10 years). We calculate the final
            deduction by taking a 3-point deduction and multiplying by the years
            beyond 10, which for a year gap of 16, would be 6. Thus: 85 - [3 ×
            6] = 85 - [18] ={' '}
            <span className="font-bold">67 is the final year score.</span>
          </p>
          <div className="font-bold text-xl mt-4 mb-2">Location Score</div>
          <p>
            We start by establishing the "distance gap"—the geographic distance
            between your guessed location and the correct location, measured as
            the shortest path along Earth's surface (as the crow flies). Based
            on your “distance gap,” we apply the below deductions from the
            appropriate scoring bracket:
          </p>
          <ol className="my-2 list-decimal ml-5">
            <li className="my-2">
              0-100km: Start at <span className="font-bold">100 points</span>,
              -0.05 per km
            </li>
            <li className="my-2">
              101-500km: Start at <span className="font-bold">95 points</span>{' '}
              (-5 from previous deductions), -0.025 per km over 100
            </li>
            <li className="my-2">
              501-1,000km: Start at <span className="font-bold">85 points</span>{' '}
              (-15 from previous deductions), -0.03 per km over 500
            </li>
            <li className="my-2">
              1,001-2,000km: Start at{' '}
              <span className="font-bold">70 points</span> (-30 from previous
              deductions), -0.02 per km over 1,000
            </li>
            <li className="my-2">
              2,001-5,000km: Start at{' '}
              <span className="font-bold">50 points</span> (-50 from previous
              deductions), -0.01 per km over 2,000
            </li>
            <li className="my-2">
              5,001-10,000km: Start at{' '}
              <span className="font-bold">20 points</span> (-80 from previous
              deductions), -0.004 per km over 5,000
            </li>
            <li>Beyond 10,000km, Score = 0</li>
          </ol>
          <p className="italic">
            Example: If the correct location is Kyiv and you guess Bucharest, we
            establish the “distance gap” between the two, which is approximately{' '}
            <span className="font-bold">748 kilometres</span>. For scoring, we
            fall into the 501-1,000km range, thus we start at 85 points (due to
            previous deductions for first 500km). We calculate the final
            deduction by taking a 0.03 per-km deduction and multiplying by the
            distance beyond 500km, which for a distance of 748, would be 248.
            Thus: 85 - [0.03 x 248] = 85 - [7.44] = 77.56. After rounding, we
            get a <span className="font-bold">final location score of 78.</span>
          </p>
        </div>
      </div>
    ),
  },
  {
    question: 'What are Hints💡 (Beta) and how do they work?',
    answer: (
      <div className="flex flex-col gap-2 text-sm md:text-base">
        <p>
          Hints are a way of improving your chances of getting a high score in
          WhenTaken Movies Daily/Archive. You can access Hints by clicking the
          lightbulb icon overlaid on the image whose year and location you are
          guessing. We plan to release additional hint types after building more
          content, but at the moment, we only have two hint types.
        </p>
        <p>
          Up to two hints can be used per round – one for location 📍and one for
          year 🗓️. Using a Hint does NOT negatively affect your score – you get
          full credit for your guess. The “cost” of the hint is twofold: 1. with
          the exception of the first hint, you must watch a rewarded ad 2. in
          your share text, we will show a light bulb💡 for each hint you use.
          This lets other know you had help and allows purists to delight in
          their light-bulb free score sharing.
        </p>
        <p>
          We believe Hints will make the game more fun for current players and
          more accessible to players who find the game too challenging. With
          that said, we are releasing Hints in Beta mode, and we will be looking
          for feedback from the community.
        </p>
      </div>
    ),
  },
  {
    question: 'How do the emojis in Share Text work?',
    answer: (
      <div className="flex flex-col gap-2 text-sm md:text-base">
        <p>Emojis are calculated based on your score and use of hints.</p>
        <p>
          <b className="font-bold">Top line emoji for total score</b>
        </p>
        <ul>
          <li>🎟️🍿🥤🍫: Total Score ≥ 800</li>
          <li>🎟️🍿🥤: 650 ≤ Total Score &lt; 800</li>
          <li>🎟️🍿: 550 ≤ Total Score &lt; 650</li>
          <li>🎟️: 375 ≤ Total Score &lt; 550</li>
          <li>👎👎: Total Score &lt; 375</li>
        </ul>
        <p>
          <b className="font-bold">Individual round score emojis</b>
        </p>
        <ul>
          <li>🥇: 175 ≤ Round Score</li>
          <li>🥈: 125 ≤ Round Score &lt; 175</li>
          <li>🥉: 75 ≤ Round Score &lt; 125</li>
          <li>🙈: Round Score &lt; 75</li>
        </ul>
      </div>
    ),
  },
  {
    question: 'What games contribute to my stats?',
    answer: (
      <p className="text-sm md:text-base">
        Only the daily game contributes to your stats. Archive games will not
        affect your stats.
      </p>
    ),
  },
  {
    question: 'Why do my stats look different?',
    answer: (
      <div className="flex flex-col gap-3 text-sm md:text-base">
        <p>
          In March of 2025, we rolled out a new{' '}
          <Link className="underline" href="/stats">
            stats page
          </Link>
          ; rather than a simple KPI presentation (cards with numbers), we now
          have an interactive bar chart, with new share functionality. This is
          part of our commitment to constantly improving the game; we hope you
          like it!
        </p>
        <p>
          That said, some users may notice their Best Scores and Average Scores
          are different than they were before, using the{' '}
          <Link className="underline" href="/stats#legacy">
            Classic View
          </Link>
          . The primary reason for this is that the Classic View does not take
          into account whether Hints were used. After rolling out Hints in
          November of 2024, we realized (a few weeks later) that we needed to
          make changes to our back end data storage to account for Hints usage.
          Thus, the{' '}
          <Link className="underline" href="/stats">
            new stats page
          </Link>{' '}
          is based on data collected since December of 2024.
        </p>
      </div>
    ),
  },
];
