'use client';

import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import { MobileFooterMenu } from '@wt/game/components/elements/footer';
import WheretakenClassicModal from '@wt/game/modals/WheretakenClassicModal';
import fonts from '@wt/shared/assets/fonts';
import { getFeedbackLink } from '@wt/utilities/feedbackLink';
import { getSiteMode } from '@wt/utilities/siteMode';
import Link from 'next/link';
import { useEffect } from 'react';

import { ExampleImage } from './ExampleImage';
import FAQ from './FAQ';
import GameButtons from './GameButtons';
import { HowToPlaySection } from './how-to-play/HowToPlayerComponents';

export default function HomePage() {
  useEffect(() => {
    if (window.location.hash && window.location.hash.startsWith('#faq')) {
      const element = document.getElementById('faq');
      if (element) {
        element.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
      }
    }
  }, []);

  return (
    <>
      {getSiteMode() == 'wheretaken' && <WheretakenClassicModal />}
      <div className="flex size-full flex-1 grow flex-col items-center justify-center gap-12 py-4 md:gap-[60px] md:py-6">
        <div className="flex w-full max-w-[1252px] flex-col gap-6 md:gap-9">
          <div className="hidden w-full max-w-[1252px] gap-6 px-8 md:flex">
            <div className="flex w-full flex-col gap-14">
              <InfoText />
              <PlayButton />
            </div>
            <ExampleImage />
          </div>
          <div className="flex flex-col gap-8 px-4 md:hidden">
            <InfoText />
            <PlayButton />
          </div>
          {getSiteMode() == 'whentaken' && <UpdatesContainer />}
          <GameButtons />
          <div className="px-4 md:hidden">
            <ExampleImage />
          </div>
        </div>
        <div className="flex max-w-[820px] flex-col gap-12 px-4 md:w-3/4 md:gap-[60px] md:px-8">
          <HowToPlaySection />
          <FAQ />
          <FeedbackSection />
        </div>
        <div className="px-4 md:hidden">
          <MobileFooterMenu />
        </div>
      </div>
    </>
  );
}

const UpdatesContainer = () => {
  return (
    <div className="flex justify-center">
      <div className="mx-2.5 mb-3 flex flex-col items-center justify-center rounded-3xl bg-[#FFEECC] px-9 py-3 text-center md:flex-row">
        <div className="max-w-[600px] text-[#4B4554]">
          Want to learn the story behind some of our images? Check out content
          provided by the WhenTaken Bot 🤖 after the Round Results.
        </div>
        <Link href="#faq-what-is-the-whentaken-bot-" className="mt-3 md:mt-0">
          <div className="flex w-[170px] items-center justify-center rounded-2xl bg-[#FFCC6A] px-4 py-2 text-center font-semibold text-[#322E38] md:ml-4">
            Learn more
            <div className="ml-4 items-center justify-center rounded-xl bg-white p-1 shadow-2xl">
              <LightbulbOutlinedIcon htmlColor="black" />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

const InfoText = () => {
  return (
    <div className="flex w-full flex-col gap-4">
      <span className="text-2xl font-semibold uppercase md:text-5xl">
        {getSiteMode() == 'whentaken' && (
          <>
            GUESS{' '}
            <span className="text-highlight dark:text-highlight-dark">
              WHEN
            </span>{' '}
            AND{' '}
            <span className="text-highlight dark:text-highlight-dark">
              WHERE
            </span>{' '}
            A PHOTO WAS TAKEN
          </>
        )}
        {getSiteMode() == 'whentaken-movies' && (
          <>
            GUESS{' '}
            <span className="text-highlight dark:text-highlight-dark">
              WHEN
            </span>{' '}
            AND{' '}
            <span className="text-highlight dark:text-highlight-dark">
              WHERE
            </span>{' '}
            A FILM WAS SET
          </>
        )}
        {getSiteMode() == 'wheretaken' && (
          <>
            GUESS{' '}
            <span className="text-highlight dark:text-highlight-dark">
              WHERE
            </span>{' '}
            A PHOTO WAS TAKEN
          </>
        )}
      </span>
      <span className="text-sm md:text-base">
        {getSiteMode() == 'whentaken' && (
          <>
            Test your detective skills with our daily game, check out the
            archive or create your own game in the Community section.
          </>
        )}
        {getSiteMode() == 'wheretaken' && (
          <>
            Test your detective skills with our daily game or check out the
            archive.
          </>
        )}
        {getSiteMode() == 'whentaken-movies' && (
          <>
            Test your movie knowledge and detective skills by guessing the year
            and location in which a movie scene was set.
          </>
        )}
      </span>
    </div>
  );
};

const FeedbackSection = () => {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-6">
      <span className="text-2xl font-semibold md:text-5xl">💌 Feedback</span>
      <div className="flex w-full flex-col rounded-2xl bg-base-200 p-6 md:flex-row">
        <div className="text-sm md:w-1/2 md:text-base">
          Something not working properly? Want to suggest a new feature? Simply
          love the game and want to give us kudos? Fill out the feedback form to
          let us know what's on your mind.
        </div>
        <div className="my-5 h-[2px] w-full bg-secondary md:mx-5 md:my-0 md:h-full md:w-[2px]"></div>
        <div className="flex flex-col items-center justify-center gap-4 md:w-1/2">
          <span
            className="text-center text-base md:text-2xl"
            style={fonts.gaegu.style}
          >
            Let us know what you think 😉
          </span>
          <Link
            href={getFeedbackLink()}
            target="_blank"
            className="btn h-[40px] min-h-[40px] w-fit shrink rounded-xl border border-gray-400 bg-base-300 text-sm font-normal text-base-100 hover:bg-base-300/80 md:text-base"
          >
            Submit Feedback
          </Link>
        </div>
      </div>
    </div>
  );
};

const PlayButton = () => {
  return (
    <Link
      href={'/game'}
      className="btn btn-primary h-[50px] min-h-[50px] w-full rounded-xl text-lg font-bold uppercase md:h-[60px] md:min-h-[60px] md:max-w-[528px] md:text-2xl"
    >
      Play Now
    </Link>
  );
};
