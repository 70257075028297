'use client';

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import useSettingsContext from '@wt/game/providers/settings/types';
import { getSiteMode } from '@wt/utilities/siteMode';
import dynamic from 'next/dynamic';
import { useParams } from 'next/navigation';
import { useEffect, useState } from 'react';

import FAQWhenTaken from './FAQWhenTaken';
import FAQWhenTakenMovies from './FAQWhenTakenMovies';
import FAQWhereTaken from './FAQWhereTaken';

const Accordion = dynamic(() => import('@mui/material/Accordion'), {
  ssr: false,
});
const AccordionDetails = dynamic(
  () => import('@mui/material/AccordionDetails'),
  { ssr: false }
);
const AccordionSummary = dynamic(
  () => import('@mui/material/AccordionSummary'),
  { ssr: false }
);

function sanitise(s: string) {
  return (
    'faq-' +
    s
      .toLowerCase()
      .replace(/\s/g, '-')
      .replace(/[^a-zA-Z0-9-]/g, '')
  );
}

export default function FAQ() {
  const [, , , setSettingsModalOpen] = useSettingsContext();
  const params = useParams();
  const [hash, setHash] = useState<string>('#');
  const [expandedAccordion, setExpandedAccordion] = useState<string>();

  useEffect(() => {
    setHash(window.location.hash);
  }, [params]);

  useEffect(() => {
    if (hash && hash.charAt(0) == '#') {
      const id = hash.substring(1);
      setExpandedAccordion(id);

      // Scroll to this accordion
      const el = document.getElementById(id);
      if (el) {
        el.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
      }
    }
  }, [hash]);

  const handleChange =
    (panelId: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panelId : undefined);
    };

  const handleSettingsClick = () => {
    const element = document.getElementById('settingsFaqLink');
    if (element) {
      setSettingsModalOpen(true);
    }
  };

  useEffect(() => {
    const element = document.getElementById('settingsFaqLink');
    if (element) {
      element.addEventListener('click', handleSettingsClick);

      return () => {
        element.removeEventListener('click', handleSettingsClick);
      };
    }
  }, [expandedAccordion]);

  let faqs = [];
  if (getSiteMode() == 'whentaken-movies') {
    faqs = FAQWhenTakenMovies;
  } else if (getSiteMode() == 'wheretaken') {
    faqs = FAQWhereTaken;
  } else {
    faqs = FAQWhenTaken;
  }

  return (
    <div id="faq" className="flex w-full flex-col items-center justify-center">
      <span className="mb-4 text-2xl font-semibold md:mb-6 md:text-5xl">
        FAQ
      </span>
      {faqs.map((faq, i) => {
        const sanitisedQuestion = sanitise(faq.question);
        return (
          <Accordion
            key={i}
            className="m-0 flex w-full flex-col items-center border-b-2 border-secondary bg-transparent py-4 text-inherit md:py-6"
            square={true}
            sx={{
              boxShadow: 'none',
              '&:before': {
                display: 'none',
              },
              backgroundImage: 'none',
              '&.MuiPaper-root': {
                margin: 0,
              },
              '& .MuiAccordionDetails-root': {
                margin: 0,
              },
              '& .MuiAccordionSummary-content': {
                margin: 0,
              },
              '& .MuiCollapse-root': {
                minWidth: '100%',
              },
            }}
            expanded={expandedAccordion == sanitisedQuestion}
            onChange={handleChange(sanitisedQuestion)}
          >
            <AccordionSummary
              className="flex w-full flex-row justify-between text-base font-semibold text-inherit hover:cursor-pointer md:text-2xl md:font-medium"
              expandIcon={<ExpandMoreRoundedIcon className="text-base-300" />}
            >
              <a id={sanitisedQuestion}></a>
              {faq.question}
            </AccordionSummary>
            <AccordionDetails>{faq.answer}</AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
