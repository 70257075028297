import Link from 'next/link';

export default [
  {
    question: 'When is a new game available?',
    answer: (
      <p className="text-sm md:text-base">
        A new game is available every day at 0:00 am, local time of your device!
      </p>
    ),
  },
  {
    question: 'Can I play past games?',
    answer: (
      <p className="text-sm md:text-base">
        Yes, you can head to the Archive section from the menu and play all of
        the WhenTaken archive games starting from the 28th of February 2024.
      </p>
    ),
  },
  {
    question: 'What are the scoring rules?',
    answer: (
      <div className="flex flex-col gap-3 text-sm md:text-base">
        <div>
          <span className="font-bold">Scoring system</span>
          <ul className="mt-2 flex flex-col gap-1 md:ml-2">
            <p>• 5 rounds will get you a maximum of 1000 points.</p>
            <p>
              • Each round 200 points: 100 max for Location and 100 max for
              Year.
            </p>
          </ul>
        </div>
        <div>
          <span className="font-bold">Scoring for guessing the year</span>
          <ul className="mt-2 flex flex-col gap-1 md:ml-2">
            <p>
              • For guesses within 5 years, the deduction is 1 point per year.
            </p>
            <p>
              • For guesses 6 to 10 years away, the deduction is 2 points per
              year after the first 5 years.
            </p>
            <p>
              • For guesses 11 to 20 years away, the deduction is 3 points per
              year after the first 10 years.
            </p>
            <p>
              • For guesses more than 20 years away, the deduction is 4 points
              per year after the first 20 years.
            </p>
          </ul>
        </div>
        <p className="italic">
          Example: The correct year is 1990. The user guesses 1974. The year
          difference between the guessed year (1974) and the correct year (1990)
          is 16 years. For the first 5 years, the deduction is 1 point per year,
          totalling 5 points deducted. For years 6 to 10, the deduction is 2
          points per year, totalling an additional 10 points deducted (2 points
          per year for 5 years). For years 11 to 16, the deduction is 3 points
          per year, totalling an additional 18 points deducted (3 points per
          year for 6 years). Therefore, the total deduction is 5 + 10 + 18 = 33.
          The total score: 100 - 33 = 67 points.
        </p>
        <div>
          <span className="font-bold">Scoring for guessing the location</span>
          <ul className="mt-2 flex flex-col gap-1 md:ml-2">
            <p>
              • The points will be awarded based on distance from the correct
              answer.
            </p>
            <p>
              • For each distance bracket, the value of 1 km will be different,
              meaning that the farther the guess is, the fewer maximum points
              the player can get.
            </p>
            <div>
              • Brackets:
              <div className="ml-4 mt-2 flex flex-col gap-1">
                <p>
                  • <span className="font-semibold">0-100</span> km: min{' '}
                  <span className="font-semibold">95 points</span>, max{' '}
                  <span className="font-semibold">100 points</span>, decrease by
                  0.05 points per km
                </p>
                <p>
                  • <span className="font-semibold">101-500</span> km: min{' '}
                  <span className="font-semibold">85 points</span>, max{' '}
                  <span className="font-semibold">95 points</span>, decrease by
                  0.025 points per km
                </p>
                <p>
                  • <span className="font-semibold">501-1000</span> km: min{' '}
                  <span className="font-semibold">70 points</span>, max{' '}
                  <span className="font-semibold">85 points</span>, decrease by
                  0.03 points per km
                </p>
                <p>
                  • <span className="font-semibold">1001-2000</span> km: min{' '}
                  <span className="font-semibold">50 points</span>, max{' '}
                  <span className="font-semibold">70 points</span>, decrease by
                  0.02 points per km
                </p>
                <p>
                  • <span className="font-semibold">2001 - 5000</span> km: min{' '}
                  <span className="font-semibold">20 points</span>, max{' '}
                  <span className="font-semibold">50 points</span>, decrease by
                  0.01 points per km
                </p>
                <p>
                  • <span className="font-semibold">5001 - 10000</span> km: min{' '}
                  <span className="font-semibold">0 points</span>, max{' '}
                  <span className="font-semibold">20 points</span>, decrease by
                  0.004 points per km
                </p>
                <p>
                  • <span className="font-semibold">≥ 10001</span> km:{' '}
                  <span className="font-semibold">0 points</span>
                </p>
              </div>
            </div>
            <p>
              • For the guessed distance within a bracket, calculate the points
              by subtracting the appropriate decrement per kilometre from the
              max points of that bracket.
            </p>
            <p>
              • <span className="font-semibold">Final formula</span>: Location
              Points=Max Points for Bracket-((Distance in km-Bracket Starting
              Distance)xPoint Decrement per km)
            </p>
          </ul>
        </div>
        <p className="italic">
          Example: The correct location is Kyiv, and the player guesses Odesa.
          The location points are calculated this way: the distance between Kyiv
          and Odesa is 475 km, which falls into the 101-500 km bracket, so the
          max number of points the user can get is 95. Calculations: Location
          Points = 95 - ((475 - 101) * 0.025) = 90 - (374 * 0.025) = 95 - 9.35 =
          85.65 points. Rounding this to the nearest whole number, it would be
          86 points.
        </p>
      </div>
    ),
  },
  {
    question: 'Why is your location incorrect?',
    answer: (
      <div className="flex flex-col gap-2 text-sm md:text-base">
        <p>
          We're doing our best to collect the most accurate data to provide you
          with the best gaming experience - we even use Street View to find the
          most accurate coordinates, however, sometimes we make mistakes or
          can't find the exact location, so bear with us. Another reason why the
          location might be slightly off is that we were unable to find the
          exact location and instead used the city coordinates.
        </p>
        <p>
          If you find a mistake, please, report it and suggest another set of
          coordinates through the form by clicking on the ⚠️ button. We really
          appreciate the input from locals who help us make our data more
          precise.
        </p>
      </div>
    ),
  },
  {
    question: 'What are Hints💡 (Beta) and how do they work?',
    answer: (
      <div className="flex flex-col gap-2 text-sm md:text-base">
        <p>
          Hints are a way of improving your chances of getting a high score in
          WhenTaken Daily/Archive. You can access Hints by clicking the
          lightbulb icon overlaid on the image whose year and location you are
          guessing.
        </p>
        <p>
          Up to two hints can be used per round – one for location 📍and one for
          year 🗓️. Using a Hint does NOT negatively affect your score – you get
          full credit for your guess. The “cost” of the hint is twofold: 1. with
          the exception of the first hint, you must watch a rewarded ad 2. in
          your share text, we will show a light bulb💡 for each hint you use.
          This lets other know you had help and allows purists to delight in
          their light-bulb free score sharing.
        </p>
        <p>
          We believe Hints will make the game more fun for current players and
          more accessible to players who find the game too challenging. With
          that said, we are releasing Hints in Beta mode, and we will be looking
          for feedback from the community.
        </p>
      </div>
    ),
  },
  {
    question: 'How do the emojis in Share Text work?',
    answer: (
      <div className="flex flex-col gap-2 text-sm md:text-base">
        <p>Emojis are calculated based on your score and use of hints.</p>
        <p>
          <b>Top line emoji</b> (e.g. the trophy 🏆 in &ldquo;I scored 865/1000
          (💡x 2) 🏆&rdquo;)
        </p>
        <ul>
          <li>👑: 900 ≤ Total Score</li>
          <li>🏆: 850 ≤ Total Score &lt; 900</li>
          <li>🏅: 750 ≤ Total Score &lt; 850</li>
          <li>🎗️: 100 ≤ Total Score &lt; 750</li>
          <li>
            💩: <span className="ml-11"></span> Total Score &lt; 100
          </li>
        </ul>
        <p>
          <b>Individual round score emojis</b> (e.g. the bronze medal 🥉 in
          &ldquo;1️⃣📍3.8K km - 🗓️ 12 yrs - 🥉111/200&rdquo;)
        </p>
        <ul>
          <li>🥇: 175 ≤ Round Score</li>
          <li>🥈: 125 ≤ Round Score &lt; 175</li>
          <li>
            🥉: <span className="ml-11"></span> Round Score &lt; 125
          </li>
        </ul>
      </div>
    ),
  },
  {
    question: 'What games contribute to my stats?',
    answer: (
      <p className="text-sm md:text-base">
        Only the daily game contributes to your stats. Community games and
        Archive games will not affect your stats.
      </p>
    ),
  },
  {
    question: 'Why do my stats look different?',
    answer: (
      <div className="flex flex-col gap-3 text-sm md:text-base">
        <p>
          In March of 2025, we rolled out a new{' '}
          <Link className="underline" href="/stats">
            stats page
          </Link>
          ; rather than a simple KPI presentation (cards with numbers), we now
          have an interactive bar chart, with new share functionality. This is
          part of our commitment to constantly improving the game; we hope you
          like it!
        </p>
        <p>
          That said, some users may notice their Best Scores and Average Scores
          are different than they were before, using the{' '}
          <Link className="underline" href="/stats#legacy">
            Classic View
          </Link>
          . The primary reason for this is that the Classic View does not take
          into account whether Hints were used. After rolling out Hints in
          November of 2024, we realized (a few weeks later) that we needed to
          make changes to our back end data storage to account for Hints usage.
          Thus, the{' '}
          <Link className="underline" href="/stats">
            new stats page
          </Link>{' '}
          is based on data collected since December of 2024.
        </p>
      </div>
    ),
  },
  {
    question: 'How do you calculate Ratings in Community Games?',
    answer: (
      <div className="flex flex-col gap-3 text-sm md:text-base">
        <p>
          We use a Bayesian scoring method to calculate ratings, which ensures a
          fair comparison between games with different numbers of ratings. This
          approach prevents games with very few ratings, like 5 out of 5 (100%),
          from being unfairly ranked higher than games with many ratings, like
          98 out of 100 (98%).
        </p>
        <div className="flex flex-col gap-1">
          <p className="font-bold">Formula:</p>
          <p>
            Weighted Rating = (
            <span className="italic">
              R<sub>tot</sub>
            </span>
            &nbsp; &frasl;&nbsp; (
            <span className="italic">
              R<sub>tot</sub>
            </span>
            &nbsp; + <span className="italic">M</span> )) &times;{' '}
            <span className="italic">P</span> + (
            <span className="italic">M</span> &nbsp;&frasl;&nbsp; (
            <span className="italic">
              R<sub>tot</sub>
            </span>
            &nbsp; + <span className="italic">M</span> )) &times;&nbsp;
            <span className="italic">
              P<sub>general</sub>
            </span>
          </p>
        </div>
        <div>
          Where:
          <div className="ml-4 mt-2 flex flex-col gap-1">
            <p>
              •{' '}
              <span className="italic">
                R<sub>tot</sub>
              </span>{' '}
              = Game's total number of ratings (fires 🔥+ poops💩)
            </p>
            <p>
              • <span className="italic">P</span> = Game's percentage of
              positive ratings = [fires 🔥 / (fires 🔥+ poops💩)]
            </p>
            <p>
              •{' '}
              <span className="italic">
                P<sub>general</sub>
              </span>{' '}
              = Average percentage across all games (e.g. 0.70
              <sup>*</sup>)
            </p>
            <p>
              • <span className="italic">M</span> = Minimum number of ratings
              threshold. (e.g. 18<sup>*</sup>)
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <p>
            <span className="underline">Example #1</span>: 5 positive out of 5
            total ratings
          </p>
          <p>
            Weighted Score = [5/(5 + 18)] &times; 1 + [18/(5 + 18)] &times; 0.7
            = 76.52%
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <p>
            <span className="underline">Example #2</span>: 98 positive out of
            100 total ratings
          </p>
          <p>
            Weighted Score = [100/(100 + 18)] &times; 0.98 + [18/(100 + 18)]
            &times; 0.7 = 95.35%
          </p>
        </div>
        <p className="italic">
          * Note that M and P<sub>general</sub> will vary based on the timeframe
          selected in the Filters section. M ranges from 18-50 and P
          <sub>general</sub> is calculated dynamically based on the average
          rating during the specified timeframe.
        </p>
      </div>
    ),
  },
  {
    question: 'How do you calculate Play Count in Community Games?',
    answer: (
      <div className="flex flex-col gap-3 text-sm md:text-base">
        <p>
          We use a normalized play count formula that adjusts the total number
          of plays logarithmically. This helps provide a fairer comparison
          between games, especially when there are outliers.
        </p>
        <p>
          Weighted Play Count = 100 &times;&nbsp; (
          <span className="text-xs md:text-sm">
            log<sub>10</sub>
          </span>
          (Play Count) &nbsp;&frasl;&nbsp;{' '}
          <span className="text-xs md:text-sm">
            log<sub>10</sub>
          </span>
          (Max Play Count))
        </p>
        <p>
          <span className="font-bold">Play Count</span> is the play count for
          the game in question.
        </p>
        <p>
          <span className="font-bold">Max Play Count</span> is the play count of
          the game that has the most plays during the given timeframe.
        </p>
        <p>
          To accurately assess an entire game’s play count, we calculate plays
          by counting the number of <i>rounds</i> played. (E.g. if someone plays
          only 3 rounds of a game then quits, that would count as 3 plays.)
        </p>
        <div className="flex flex-col gap-2">
          <p>
            Example comparing 3 games by “Number of Plays” over “Past 6 Months”
          </p>
          <p>Max Play Count (over past 6 months) = 10,000</p>
          <p>
            <span className="underline">Game A</span>: 10,000 plays
          </p>
          <p>
            Weighted Play Count = 100 &times; [
            <span className="text-xs md:text-sm">
              log<sub>10</sub>
            </span>
            (10,000)] / [
            <span className="text-xs md:text-sm">
              log<sub>10</sub>
            </span>
            (10,000)] = 100 &times; [4/4] ={' '}
            <span className="font-bold">100%</span>
          </p>
          <p>
            <span className="underline">Game B</span>: 5,000 plays
          </p>
          <p>
            Weighted Play Count = 100 &times; [
            <span className="text-xs md:text-sm">
              log<sub>10</sub>
            </span>
            (5,000)] / [
            <span className="text-xs md:text-sm">
              log<sub>10</sub>
            </span>
            (10,000)] ≈ 100 &times; [3.6990/4] ≈{' '}
            <span className="font-bold">92.48%</span>
          </p>
          <p>
            <span className="underline">Game C</span>: 4,500 plays
          </p>
          <p>
            Weighted Play Count = 100 &times; [
            <span className="text-xs md:text-sm">
              log<sub>10</sub>
            </span>
            (4,500)] / [
            <span className="text-xs md:text-sm">
              log<sub>10</sub>
            </span>
            (10,000)] ≈ 100 &times; [3.6532/4] ≈{' '}
            <span className="font-bold">91.33%</span>
          </p>
          <p>
            Note that without normalizing, Games B and C would receive scores of{' '}
            <i>50%</i> and <i>45%</i> respectively. In scenarios where a single
            game is considerably higher than all the rest, these scores would
            not be, in our minds, reflective of how popular the games are.
          </p>
        </div>
      </div>
    ),
  },
  {
    question: 'What is the WhenTaken Bot 🤖?',
    answer: (
      <div className="text-sm md:text-base">
        <p>
          The WhenTaken Bot is our latest effort to make WhenTaken fun and
          educational. Each day, the bot has three jobs:
        </p>
        <br />
        <ol className="md:ml-2">
          <li>
            1. Analyze the photo in question and take its best guess on location
            + year
          </li>
          <li>
            2. Provide an image analysis, explaining what clues could have led
            to the correct answers.
          </li>
          <li>
            3. Draft a short history lesson based on the image (image itself,
            year, location, and caption).
          </li>
        </ol>
      </div>
    ),
  },
  {
    question: 'Does the WhenTaken Bot 🤖 cheat?',
    answer: (
      <p className="text-sm md:text-base">
        No. Like you, the WhenTaken Bot 🤖 has no access to the image's metadata
        (year, location, caption). Its guesses are based solely on a visual
        inspection of the image itself. Our Bot is trained on a vast wealth of
        data, which makes it surprisingly good at this game. But as you'll see,
        there are times when it completely whiffs the answer.
      </p>
    ),
  },
  {
    question:
      'Why does the WhenTaken Bot 🤖 give wrong information or say illogical things?',
    answer: (
      <div className="text-sm md:text-base">
        <p>
          Sometimes our game is really hard! Like you, the WhenTaken Bot 🤖 take
          its best guess, but sometimes it's just wrong. Unlike you, the
          WhenTaken Bot 🤖 is an AI, meaning it relies on probabilistic machine
          learning models to make predictions based on patterns in its training
          data. As with many AIs, this means it can:
        </p>
        <br />
        <ul className="md:ml-2">
          <li>
            • Generate confident-sounding answers that might be completely
            off-base
          </li>
          <li>
            • Struggle with context that requires nuanced human understanding
          </li>
          <li>• Misinterpret subtle visual cues or cultural references</li>
          <li>
            • Produce responses that seem logical at first glance but fall apart
            under closer scrutiny
          </li>
        </ul>
        <br />
        <p>
          The bot doesn't have true understanding - not of the image or even the
          very specific instructions we've given it. Speaking of those
          instructions, we've done our best to build in safeguards, including a
          requirement to run its answers through a series of accuracy checks.
          While some inaccuracies or questionable statements may slip through,
          by and large, we hope the WhenTaken Bot 🤖 helps our users learn more
          and have fun.
        </p>
      </div>
    ),
  },
  {
    question: 'Is the WhenTaken Bot 🤖 destroying the environment?',
    answer: (
      <p className="text-sm md:text-base">
        As you may know, AI is extremely energy-intensive, and as such, we've
        had users (rightly!) express concern that for every user who plays, our
        WhenTaken Bot 🤖 is destroying the environment. Here we have good
        news:&nbsp;
        <span className="font-bold">
          All users see the same content, which is pre-loaded for everyone
          once,&nbsp;
          <span className="underline">not</span> generated on-demand for each
          user.
        </span>
        &nbsp; (The impression of on-demand generation comes from a neat&nbsp;
        <span className="font-bold">typewriter effect in javascript</span>,
        whereby the text is progressively revealed.)
      </p>
    ),
  },
  {
    question: 'Can I turn off the WhenTaken Bot 🤖?',
    answer: (
      <p className="text-sm md:text-base">
        Yes, in the{' '}
        <span id="settingsFaqLink" className="cursor-pointer underline">
          settings
        </span>
        . There, you can toggle any (or all) of the sections produced by the
        WhenTaken Bot 🤖. If you are turning off the bot due to concerns over
        AI's environmental impact, please see the FAQ on the environmental
        impact of the WhenTaken Bot 🤖 above.
      </p>
    ),
  },
];
